<template>
  <v-app justify="center">
    <v-container
      id="Apply"
      tag="section"
    >
    <!-- <v-main> -->
      <base-subheading><span class="headline">{{ $t('MeeTeeMeeNgern1.applicationForm') }}</span></base-subheading>
      <v-row row wrap>
        <v-col cols="12" class="pt-0 pb-2 mb-0" lg="2">
          <v-autocomplete
            dense
            hide-details
            outlined
            :label="$t('MeeTeeMeeNgern1.product')"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="pt-0 pb-2 mb-0" lg="2">
          <v-text-field
            dense hide-details 
            :label="$t('MeeTeeMeeNgern1.NameSurName')" 
            v-model="NameSurName"
            outlined>
          </v-text-field>
        </v-col>
        <v-col cols="12" class="pt-0 pb-2 mb-0" lg="2">
          <v-text-field
            dense hide-details 
            :label="$t('MeeTeeMeeNgern1.Tel')" 
            v-model="NameSurName"
            outlined>
          </v-text-field>
        </v-col>
        <v-col cols="12" class="pt-0 pb-2 mb-0" lg="1">
          <v-autocomplete
            dense
            hide-details
            outlined
            :label="$t('MeeTeeMeeNgern1.provinces')"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="pt-0 pb-2 mb-0" lg="1">
          <v-autocomplete
            dense
            hide-details
            outlined
            :label="$t('MeeTeeMeeNgern1.amphur')"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="pt-0 pb-2 mb-0" lg="1">
          <v-autocomplete
            dense
            hide-details
            outlined
            :label="$t('MeeTeeMeeNgern1.tambon')"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="pt-0 pb-2 mb-0" lg="1">
          <v-text-field
            dense hide-details 
            :label="$t('MeeTeeMeeNgern1.RefCode')" 
            v-model="NameSurName"
            outlined>
          </v-text-field>
        </v-col>
      </v-row>
    <!-- </v-main> -->
    </v-container>
  </v-app>
</template>

<script>
import 'babel-polyfill'
import Base64Binary from 'base64-arraybuffer'
export default {
  name: 'Apply',
  props: ['receiveVar'],
  data () {
    return {
      utils: require('@/utils'),
      stringFormat: require('string-format'),
      appName: process.env.VUE_APP_DB_APP_NAME,
      dialog: {
        dialogProgress: false,
        dialogAddEditWindow: false,
        dialogPathoRegisterReport: false,
        dialogReceivedDateFromTo: false,
        dialogBrowseVendor: false,
        dialogInvtReceiveFromSupplierAction: false,
      },
      dropDownData: [],
      NameSurName: '',
    }
  },
  created: function () {
    console.log(this.receiveVar, 'this.receiveVar')
  },
  methods: {
    selectLanguage (language) {
      console.log(language, 'language componnet apply')
    },
  }
}
</script>
<style scoped lang="scss">
 ::v-deep .v-application--wrap {
    min-height: fit-content;
  }
</style>
